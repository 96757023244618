import { Card, CardBody } from 'reactstrap';
import AllPatientList from './Components/AllPatientsList';

const AllPatient = () => {
  return (
    <Card>
      <CardBody>
        <AllPatientList />
      </CardBody>
    </Card>
  );
};

export default AllPatient;
