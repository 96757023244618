import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL, FETCH_PATIENT } from '../Utils/API';

// Async thunk for fetching patient data
export const fetchPatient = createAsyncThunk('patient/fetchPatient', async (_, thunkAPI) => {
  try {
    const response = await axios.get(BASE_URL + FETCH_PATIENT);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

const patientSlice = createSlice({
  name: 'patient',
  initialState: {
    patientList: [],
    loading: false,
    error: null,
  },
  reducers: {
    // Non-async reducers can go here
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPatient.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPatient.fulfilled, (state, action) => {
        state.loading = false;
        state.patientList = action.payload;
      })
      .addCase(fetchPatient.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to fetch patient data';
      });
  },
});

export default patientSlice.reducer;
