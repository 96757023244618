import React, { useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from 'reactstrap';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom'; // Assuming you are using react-router for routing
import { useSelector } from 'react-redux';
import { BASE_URL, UPDATE_PATIENT } from '../../Utils/API';
import ToastNotification from '../../Utils/ToastNotification';

const EditPatient = () => {
  const { id } = useParams(); // Get the patient ID from the route parameters
  const navigate = useNavigate();
  const { patientList } = useSelector((state) => state.patient);
  const [formData, setFormData] = useState({
    patientName: '',
    relation: '',
    age: '',
    gender: '',
    address: '',
    weight: '',
    bloodPressure: '',
    mobileNumber: '',
    careOf: '',
    referredByName: '',
    departmentOfTreatment: '',
    emergencyCareType: '',
    surgeryDetails: {
      nameOfSurgery: '',
      chargesFinalised: '',
    },
    doctorName: '',
    doctorFee: '',
    referredToMedicine: '',
    referredToLabs: '',
    labDetails: '',
    patientEntryDateTime: '',
    patientDischargeDateTime: '',
    patientRecords: '', // Will hold Base64 encoded string
    comment: '', // Comment field
  });

  useEffect(() => {
    // Find the patient by ID from the patient list
    const patientData = patientList.find((patient) => patient.id === parseInt(id, 10));

    if (patientData) {
      // Set the form data with the found patient data
      setFormData({
        patientName: patientData.patientName || '',
        relation: patientData.relation || '',
        age: patientData.age || '',
        gender: patientData.gender || '',
        address: patientData.address || '',
        weight: patientData.weight || '',
        bloodPressure: patientData.bloodPressure || '',
        mobileNumber: patientData.mobileNumber || '',
        careOf: patientData.careOf || '',
        referredByName: patientData.referredByName || '',
        departmentOfTreatment: patientData.departmentOfTreatment || '',
        emergencyCareType: patientData.emergencyCareType || '',
        surgeryDetails: {
          nameOfSurgery: patientData.surgeryDetails?.nameOfSurgery || '',
          chargesFinalised: patientData.surgeryDetails?.chargesFinalised || '',
        },
        doctorName: patientData.doctorName || '',
        doctorFee: patientData.doctorFee || '',
        referredToMedicine: patientData.referredToMedicine || '',
        referredToLabs: patientData.referredToLabs || '',
        labDetails: patientData.labDetails || '',
        patientEntryDateTime: patientData.patientEntryDateTime || '',
        patientDischargeDateTime: patientData.patientDischargeDateTime || '',
        patientRecords: patientData.patientRecords || '',
        comment: patientData.comment || '',
      });
    } else {
      console.error('Patient not found in the patient list');
    }
  }, [id, patientList]);

  // Helper function to convert file to Base64
  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
    });
  };

  const handleInputChange = async (e) => {
    const { name, value, type, files } = e.target;

    if (type === 'file') {
      // Convert the file to Base64
      const file = files[0];
      const base64File = await convertFileToBase64(file);
      setFormData({ ...formData, patientRecords: base64File });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Add id to formData
    const updatedFormData = { ...formData, id };

    axios
      .post(BASE_URL + UPDATE_PATIENT, updatedFormData) // Sending id as part of the formData
      .then((response) => {
        if (response?.data?.statusCode === '1') {
          console.log('Staff details updated successfully', response);

          ToastNotification('success', response?.data?.message);
          navigate('/skch-reception/all-patient');
        } else {
          ToastNotification('error', response?.data?.message);
        }
      })
      .catch((error) => {
        console.error('There was an error updating the staff data!', error);
        ToastNotification('error', 'Error updating staff details. Please try again.');
      });
  };

  return (
    <div>
      <Row>
        <Col md="12">
          <Card>
            <CardTitle tag="h4" className="border-bottom bg-primary p-3 mb-0 text-white">
              Edit Patient
            </CardTitle>
            <CardBody>
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label>Patient Name</Label>
                      <Input
                        type="text"
                        name="patientName"
                        value={formData.patientName}
                        onChange={handleInputChange}
                        placeholder="Enter patient's name"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label>Son of / Daughter of / Wife of</Label>
                      <Input
                        type="text"
                        name="relation"
                        value={formData.relation}
                        onChange={handleInputChange}
                        placeholder="Enter relation"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label>Age</Label>
                      <Input
                        type="number"
                        name="age"
                        value={formData.age}
                        onChange={handleInputChange}
                        placeholder="Enter age"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label>Gender</Label>
                      <Input
                        type="select"
                        name="gender"
                        value={formData.gender}
                        onChange={handleInputChange}
                      >
                        <option value="">Select gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label>Address</Label>
                      <Input
                        type="text"
                        name="address"
                        value={formData.address}
                        onChange={handleInputChange}
                        placeholder="Enter address"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label>Weight</Label>
                      <Input
                        type="number"
                        name="weight"
                        value={formData.weight}
                        onChange={handleInputChange}
                        placeholder="Enter weight"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label>Blood Pressure</Label>
                      <Input
                        type="text"
                        name="bloodPressure"
                        value={formData.bloodPressure}
                        onChange={handleInputChange}
                        placeholder="Enter blood pressure"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label>Mobile Number</Label>
                      <Input
                        type="text"
                        name="mobileNumber"
                        value={formData.mobileNumber}
                        onChange={handleInputChange}
                        placeholder="Enter mobile number"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label>Care of</Label>
                      <Input
                        type="select"
                        name="careOf"
                        value={formData.careOf}
                        onChange={handleInputChange}
                      >
                        <option value="">Select care of</option>
                        <option value="Referred">Referred by someone</option>
                        <option value="Self">Self</option>
                      </Input>
                    </FormGroup>
                    {formData.careOf === 'Referred' && (
                      <FormGroup>
                        <Label>Referred by Name</Label>
                        <Input
                          type="text"
                          name="referredByName"
                          value={formData.referredByName}
                          onChange={handleInputChange}
                          placeholder="Enter name"
                        />
                      </FormGroup>
                    )}
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label>Department of Treatment</Label>
                      <Input
                        type="select"
                        name="departmentOfTreatment"
                        value={formData.departmentOfTreatment}
                        onChange={handleInputChange}
                      >
                        <option value="">Select department</option>
                        <option value="OPD">OPD</option>
                        <option value="IPD">IPD</option>
                        <option value="Surgery">Surgery</option>
                      </Input>
                    </FormGroup>
                    {formData.departmentOfTreatment === 'IPD' && (
                      <FormGroup>
                        <Label>Type of Emergency Care</Label>
                        <Input
                          type="select"
                          name="emergencyCareType"
                          value={formData.emergencyCareType}
                          onChange={handleInputChange}
                        >
                          <option value="">Select Emergency Care</option>
                          <option value="ICU">IN ICU WARD</option>
                          <option value="NICU">IN NICU WARD</option>
                          <option value="HDU">IN HDU WARD</option>
                          <option value="General">IN GENERAL WARD</option>
                          <option value="GYNAC">IN GYNAC WARD</option>
                          <option value="Emergency">IN EMERGENCY WARD</option>
                          <option value="OperationTheatre">IN OPERATION THEATRE</option>
                        </Input>
                      </FormGroup>
                    )}
                    {formData.departmentOfTreatment === 'Surgery' && (
                      <div>
                        <FormGroup>
                          <Label>Name of Surgery</Label>
                          <Input
                            type="text"
                            name="surgeryDetails.nameOfSurgery"
                            value={formData.surgeryDetails.nameOfSurgery}
                            onChange={handleInputChange}
                            placeholder="Enter surgery name"
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label>Charges Finalised</Label>
                          <Input
                            type="number"
                            name="surgeryDetails.chargesFinalised"
                            value={formData.surgeryDetails.chargesFinalised}
                            onChange={handleInputChange}
                            placeholder="Enter surgery charges"
                          />
                        </FormGroup>
                      </div>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label>Doctor Name</Label>
                      <Input
                        type="text"
                        name="doctorName"
                        value={formData.doctorName}
                        onChange={handleInputChange}
                        placeholder="Enter doctor's name"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label>Doctor Fee</Label>
                      <Input
                        type="number"
                        name="doctorFee"
                        value={formData.doctorFee}
                        onChange={handleInputChange}
                        placeholder="Enter doctor fee"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label>Referred to SKCH Medicine</Label>
                      <Input
                        type="select"
                        name="referredToMedicine"
                        value={formData.referredToMedicine}
                        onChange={handleInputChange}
                      >
                        <option value="">Select</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  {formData.referredToMedicine === 'Yes' && (
                    <Col md="6">
                      <FormGroup>
                        <Label>Referred to Hospital Labs</Label>
                        <Input
                          type="select"
                          name="referredToLabs"
                          value={formData.referredToLabs}
                          onChange={handleInputChange}
                        >
                          <option value="">Select</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </Input>
                      </FormGroup>
                      {formData.referredToLabs === 'Yes' && (
                        <FormGroup>
                          <Label>Lab Details</Label>
                          <Input
                            type="text"
                            name="labDetails"
                            value={formData.labDetails}
                            onChange={handleInputChange}
                            placeholder="Enter lab details"
                          />
                        </FormGroup>
                      )}
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label>Patient Entry Date & Time</Label>
                      <Input
                        type="datetime-local"
                        name="patientEntryDateTime"
                        value={formData.patientEntryDateTime}
                        onChange={handleInputChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label>Patient Discharge Date & Time</Label>
                      <Input
                        type="datetime-local"
                        name="patientDischargeDateTime"
                        value={formData.patientDischargeDateTime}
                        onChange={handleInputChange}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <FormGroup>
                      <Label for="comment">Comment</Label>
                      <Input
                        type="textarea"
                        name="comment"
                        id="comment"
                        value={formData.comment}
                        onChange={handleInputChange}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup>
                  <Label>Patient Records (Upload Photo)</Label>
                  <Input type="file" name="patientRecords" onChange={handleInputChange} />
                </FormGroup>
                <Button type="submit" color="primary">
                  Update Patient
                </Button>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default EditPatient;
