import React, { useEffect, useState } from 'react';
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Card,
  CardTitle,
  CardBody,
  Row,
  Col,
} from 'reactstrap';
import Select from 'react-dropdown-select';
import { v4 as uuidv4 } from 'uuid'; // Import uuid to generate unique IDs
import axios from 'axios'; // Import axios for API call
import { useNavigate } from 'react-router-dom';
import { BASE_URL, CREATE_CHARGES_TYPE, CREATE_INVOICE, FETCH_CHARGES_TYPE } from '../../Utils/API';
import ToastNotification from '../../Utils/ToastNotification';

const PatientInvoice = () => {
  const navigate = useNavigate();
  const [invoiceData, setInvoiceData] = useState({
    treatmentType: 'OPD', // OPD, Surgery, HealthCard, IPD
    chargesType: 'ABC ddg',
    fixedCharges: '',
    discountType: 'flat', // flat or percentage
    flatDiscount: '',
    percentageDiscount: '',
    surgeryType: '',
    packageAvailable: '',
    hospitalRep: '',
    variableCharges: [{ id: uuidv4(), type: '', amount: '' }], // Generate unique ID for each charge
    paymentMode: 'Cash', // Cash / UPI
    paymentStatus: 'Paid', // Paid / Dues
    promisedPaymentDate: '',
    guarantorDetails: '',
  });

  const [chargesType, setChargesType] = useState('');
  const [allChargesType, setAllChargesType] = useState([]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInvoiceData({ ...invoiceData, [name]: value });
  };

  const handleVariableChargeChange = (id, field, value) => {
    const updatedCharges = invoiceData.variableCharges.map((charge) =>
      charge.id === id ? { ...charge, [field]: value } : charge,
    );
    setInvoiceData({ ...invoiceData, variableCharges: updatedCharges });
  };

  const addNewCharge = () => {
    setInvoiceData({
      ...invoiceData,
      variableCharges: [...invoiceData.variableCharges, { id: uuidv4(), type: '', amount: '' }],
    });
  };

  const removeCharge = (id) => {
    const updatedCharges = invoiceData.variableCharges.filter((charge) => charge.id !== id);
    setInvoiceData({ ...invoiceData, variableCharges: updatedCharges });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // API call to submit the invoice data
    try {
      const response = await axios.post(BASE_URL + CREATE_INVOICE, invoiceData);

      console.log('Invoice successfully created', response.data);

      // Set the toast notification for success
      ToastNotification('success', 'Invoice successfully created');

      // Optionally redirect or perform other actions after success
      setTimeout(() => {
        navigate('/skch-reception/all-patient'); // Redirect to invoices list or desired page
      }, 2000);
    } catch (error) {
      console.error('Error creating invoice', error);
      ToastNotification('error', 'Error creating invoice');
    }
  };

  const fetchChargesType = async () => {
    try {
      const response = await fetch(BASE_URL + FETCH_CHARGES_TYPE);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      console.log('data', data); // Process the fetched data here
      setAllChargesType(data?.data);
    } catch (error) {
      console.error('Error fetching charges type:', error);
    }
  };

  useEffect(() => {
    fetchChargesType();
  }, []);

  const handleChargesTypeSubmit = async (e) => {
    e.preventDefault();
    // API call to submit the invoice data
    if (chargesType !== '') {
      try {
        const response = await axios.post(BASE_URL + CREATE_CHARGES_TYPE, { chargesType });
        console.log(response);

        ToastNotification('success', 'Charges create successfully');
        setChargesType('');
        fetchChargesType();
        // Show success message or handle navigation after success
      } catch (error) {
        console.error('Error creating invoice', error);
        // Show error message
      }
    } else {
      ToastNotification('error', 'Please Enter your charges name');
    }
  };

  // const calculateDiscount = () => {
  //   const { discountType, flatDiscount, percentageDiscount, fixedCharges } = invoiceData;
  //   if (discountType === 'flat') {
  //     return flatDiscount;
  //   }
  //   if (discountType === 'percentage') {
  //     return (percentageDiscount / 100) * fixedCharges;
  //   }
  //   return 0;
  // };

  return (
    <div style={{ background: '#f7f7f7', padding: '20px', minHeight: '100vh' }}>
      <Card
        style={{
          maxWidth: '800px',
          margin: '0 auto',
          padding: '20px',
          background: '#fff',
          borderRadius: '10px',
        }}
      >
        <CardTitle
          tag="h4"
          style={{
            background: '#fb9678',
            color: '#fff',
            padding: '10px',
            borderRadius: '10px',
            textAlign: 'center',
            marginBottom: '20px',
          }}
        >
          Create Patient Invoice
        </CardTitle>

        <CardBody>
          <Form onSubmit={handleChargesTypeSubmit}>
            <Row className="align-content-center">
              <Label>Create Charges Type</Label>
              <Col md={10}>
                <FormGroup>
                  <Input
                    type="text"
                    value={chargesType}
                    onChange={(e) => setChargesType(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col md={2}>
                <Button type="submit" color="primary">
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
          <Form onSubmit={handleSubmit}>
            {/* Treatment Type */}
            <FormGroup>
              <Label for="treatmentType">Treatment Type</Label>
              <Input
                type="select"
                name="treatmentType"
                id="treatmentType"
                value={invoiceData.treatmentType}
                onChange={handleInputChange}
              >
                {/* <option value="OPD">OPD</option>
                <option value="Surgery">Surgery</option>
                <option value="HealthCard">Health Card Making</option>
                <option value="IPD">IPD</option> */}
                {allChargesType?.map((ele) => {
                  return (
                    <option key={ele?.id} value={ele?.charges_type}>
                      {ele?.charges_type}
                    </option>
                  );
                })}
              </Input>
            </FormGroup>

            {/* Fixed Charges */}
            {(invoiceData.treatmentType === 'OPD' ||
              invoiceData.treatmentType === 'HealthCard') && (
              <>
                <FormGroup>
                  <Label for="fixedCharges">Fixed Charges</Label>
                  <Input
                    type="number"
                    name="fixedCharges"
                    id="fixedCharges"
                    value={invoiceData.fixedCharges}
                    onChange={handleInputChange}
                  />
                </FormGroup>

                {/* Discount Type */}
                <FormGroup>
                  <Label for="discountType">Discount Type</Label>
                  <Input
                    type="select"
                    name="discountType"
                    id="discountType"
                    value={invoiceData.discountType}
                    onChange={handleInputChange}
                  >
                    <option value="flat">Flat Discount</option>
                    <option value="percentage">Percentage Discount</option>
                  </Input>
                </FormGroup>

                {/* Flat Discount */}
                {invoiceData.discountType === 'flat' && (
                  <FormGroup>
                    <Label for="flatDiscount">Flat Discount</Label>
                    <Input
                      type="number"
                      name="flatDiscount"
                      id="flatDiscount"
                      value={invoiceData.flatDiscount}
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                )}

                {/* Percentage Discount */}
                {invoiceData.discountType === 'percentage' && (
                  <FormGroup>
                    <Label for="percentageDiscount">Percentage Discount</Label>
                    <Input
                      type="number"
                      name="percentageDiscount"
                      id="percentageDiscount"
                      value={invoiceData.percentageDiscount}
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                )}
              </>
            )}

            {/* Surgery Type and Variable Charges for IPD */}
            {/* Rest of the form remains unchanged */}

            {/* Surgery Type (for Surgery treatment type) */}
            {invoiceData.treatmentType === 'Surgery' && (
              <FormGroup>
                <Label for="surgeryType">Surgery Type</Label>
                <Input
                  type="text"
                  name="surgeryType"
                  id="surgeryType"
                  value={invoiceData.surgeryType}
                  onChange={handleInputChange}
                />
              </FormGroup>
            )}

            {/* Package Available (for Surgery treatment type) */}
            {invoiceData.treatmentType === 'Surgery' && (
              <FormGroup>
                <Label for="packageAvailable">Package Available</Label>
                <Input
                  type="text"
                  name="packageAvailable"
                  id="packageAvailable"
                  value={invoiceData.packageAvailable}
                  onChange={handleInputChange}
                />
              </FormGroup>
            )}

            {/* Variable Charges (for IPD treatment type) */}
            {invoiceData.treatmentType === 'IPD' && (
              <>
                <h5 style={{ fontWeight: 600, textDecoration: 'underline', paddingTop: '20px' }}>
                  Variable Charges :
                </h5>
                {invoiceData.variableCharges.map((charge) => (
                  <div
                    key={charge.id}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: '10px',
                      marginTop: '20px',
                    }}
                  >
                    <FormGroup style={{ flex: 1, marginRight: '10px' }}>
                      <Label for={`chargeType-${charge.id}`}>Charge Type</Label>
                      <Select
                        style={{ borderRadius: '5px' }}
                        options={[
                          { value: 'Doctor Fee', label: 'Doctor Fee' },
                          { value: 'Nursing Charges', label: 'Nursing Charges' },
                          { value: 'Bed Charges', label: 'Bed Charges' },
                          { value: 'Emergency Charges', label: 'Emergency Charges' },
                          { value: 'Oxygen Charges', label: 'Oxygen Charges' },
                          { value: 'Medicine Charges', label: 'Medicine Charges' },
                        ]}
                        values={charge.type ? [{ value: charge.type, label: charge.type }] : []}
                        onChange={(values) =>
                          handleVariableChargeChange(charge.id, 'type', values[0]?.value)
                        }
                        searchable
                      />
                    </FormGroup>
                    <FormGroup style={{ flex: 1, marginRight: '10px' }}>
                      <Label for={`chargeAmount-${charge.id}`}>Charge Amount</Label>
                      <Input
                        type="number"
                        name={`chargeAmount-${charge.id}`}
                        id={`chargeAmount-${charge.id}`}
                        value={charge.amount}
                        onChange={(e) =>
                          handleVariableChargeChange(charge.id, 'amount', e.target.value)
                        }
                      />
                    </FormGroup>
                    {invoiceData.variableCharges.length > 1 && (
                      <Button color="danger" onClick={() => removeCharge(charge.id)}>
                        Remove
                      </Button>
                    )}
                  </div>
                ))}
                <Button color="success" onClick={addNewCharge} style={{ marginBottom: '20px' }}>
                  Add New Charge
                </Button>
              </>
            )}

            {/* Hospital Representative (for HealthCard treatment type) */}
            {invoiceData.treatmentType === 'HealthCard' && (
              <FormGroup>
                <Label for="hospitalRep">Hospital Representative</Label>
                <Input
                  type="text"
                  name="hospitalRep"
                  id="hospitalRep"
                  value={invoiceData.hospitalRep}
                  onChange={handleInputChange}
                />
              </FormGroup>
            )}

            {/* Payment Mode */}
            <FormGroup>
              <Label for="paymentMode">Payment Mode</Label>
              <Input
                type="select"
                name="paymentMode"
                id="paymentMode"
                value={invoiceData.paymentMode}
                onChange={handleInputChange}
              >
                <option value="Cash">Cash</option>
                <option value="UPI">UPI</option>
              </Input>
            </FormGroup>

            {/* Payment Status */}
            <FormGroup>
              <Label for="paymentStatus">Payment Status</Label>
              <Input
                type="select"
                name="paymentStatus"
                id="paymentStatus"
                value={invoiceData.paymentStatus}
                onChange={handleInputChange}
              >
                <option value="Paid">Paid</option>
                <option value="Dues">Dues</option>
              </Input>
            </FormGroup>

            {/* Promised Payment Date (if payment status is 'Dues') */}
            {invoiceData.paymentStatus === 'Dues' && (
              <FormGroup>
                <Label for="promisedPaymentDate">Promised Payment Date</Label>
                <Input
                  type="date"
                  name="promisedPaymentDate"
                  id="promisedPaymentDate"
                  value={invoiceData.promisedPaymentDate}
                  onChange={handleInputChange}
                />
              </FormGroup>
            )}

            {/* Guarantor Details (if payment status is 'Dues') */}
            {invoiceData.paymentStatus === 'Dues' && (
              <FormGroup>
                <Label for="guarantorDetails">Guarantor Details</Label>
                <Input
                  type="textarea"
                  name="guarantorDetails"
                  id="guarantorDetails"
                  value={invoiceData.guarantorDetails}
                  onChange={handleInputChange}
                />
              </FormGroup>
            )}

            <Button type="submit" color="primary">
              Submit Invoice
            </Button>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
};

export default PatientInvoice;
