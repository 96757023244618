export const BASE_URL = 'https://pharmacyapi.hybridtech.in/api';
export const ADD_STAFF = '/staff/addStaff.php';
export const UPDATE_STAFF = '/staff/updateStaff.php';
export const UPDATE_PATIENT = '/patient/updatePatient.php';
export const FETCH_STAFF = '/staff/fetchStaff.php';
export const ADD_ATTANDANCE = '/staff/addAttendance.php';
export const ADD_ACHIVMENT = '/staff/addAchievements.php';
export const ADD_TASK = '/staff/addTask.php';
export const ADD_PATIENT = '/patient/addPatient.php';
export const HOSPITAL_IMCOME = '/hospital/addIncome.php';
export const HOSPITAL_EXPENSE = '/hospital/addExpense.php';
export const FETCH_PATIENT = '/patient/fetchPatient.php';
export const CREATE_INVOICE = '/invoice/addInvoice.php';
export const FETCH_CHARGES_TYPE = '/invoice/fetchChargesType.php';
export const CREATE_CHARGES_TYPE = '/invoice/addChargesType.php';
