import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { FaEllipsisV, FaDownload } from 'react-icons/fa';
import { fetchPatient } from '../../../store/patientSlice';

const AllPatientList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleEdit = (patient) => {
    navigate(`/skch-reception/patient-edit/${patient.id}`, { state: { patient } });
  };

  const handleCreateInvoice = (patient) => {
    navigate(`/skch-reception/patient-invoice/${patient.id}`);
  };

  // const handleViewDetails = (patient) => {
  //   navigate(`/skch-management/patient-details/${patient.id}`);
  // };

  const { patientList } = useSelector((state) => state.patient);

  useEffect(() => {
    dispatch(fetchPatient());
  }, [dispatch]);

  return (
    <div>
      <Table className="align-middle">
        <thead>
          <tr>
            <th>No</th>
            <th>Patient Name</th>
            <th>Age</th>
            <th>Gender</th>
            <th>Address</th>
            <th>Doctor Name</th>
            <th>Department</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {patientList.map((patient, index) => (
            <tr key={patient.id}>
              <td>{index + 1}</td>
              <td>{patient.patientName}</td>
              <td>{patient.age}</td>
              <td>{patient.gender}</td>
              <td>{patient.address}</td>
              <td>{patient.doctorName}</td>
              <td>{patient.departmentOfTreatment}</td>
              <td className="d-flex align-items-center gap-4">
                <UncontrolledDropdown>
                  <DropdownToggle tag="div">
                    <div style={{ cursor: 'pointer' }}>
                      <FaEllipsisV />
                    </div>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => handleEdit(patient)}>Edit</DropdownItem>
                    <DropdownItem onClick={() => handleCreateInvoice(patient)}>
                      Create Invoice
                    </DropdownItem>
                    {/* <DropdownItem onClick={() => handleViewDetails(patient)}>
                      View Details
                    </DropdownItem> */}
                  </DropdownMenu>
                </UncontrolledDropdown>
                {patient?.invoice === 'true' ? <FaDownload style={{ cursor: 'pointer' }} /> : null}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default AllPatientList;
