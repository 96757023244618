import React, { useEffect, useState } from 'react';
import { Form, FormGroup, Label, Input, Button, Card, CardTitle, CardBody } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid'; // Import uuid to generate unique IDs
import axios from 'axios'; // Import axios for API call
import { useParams } from 'react-router-dom'; // To get invoice ID from URL

const EditInvoice = () => {
  const { invoiceId } = useParams(); // Get the invoice ID from the URL
  const [invoiceData, setInvoiceData] = useState({
    treatmentType: 'OPD', // OPD, Surgery, HealthCard, IPD
    fixedCharges: '',
    discount: '',
    surgeryType: '',
    packageAvailable: '',
    hospitalRep: '',
    variableCharges: [{ id: uuidv4(), type: '', amount: '' }],
    paymentMode: 'Cash', // Cash / UPI
    paymentStatus: 'Paid', // Paid / Dues
    promisedPaymentDate: '',
    guarantorDetails: '',
  });

  // Fetch the existing invoice data on component load
  useEffect(() => {
    const fetchInvoiceData = async () => {
      try {
        const response = await axios.get(`https://api.example.com/invoice/${invoiceId}`);
        setInvoiceData(response.data); // Prefill form with fetched data
      } catch (error) {
        console.error('Error fetching invoice data', error);
      }
    };
    fetchInvoiceData();
  }, [invoiceId]);

  // Handle input field changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInvoiceData({ ...invoiceData, [name]: value });
  };

  // Handle variable charges updates
  const handleVariableChargeChange = (id, field, value) => {
    const updatedCharges = invoiceData.variableCharges.map((charge) =>
      charge.id === id ? { ...charge, [field]: value } : charge,
    );
    setInvoiceData({ ...invoiceData, variableCharges: updatedCharges });
  };

  // Add new charge dynamically
  const addNewCharge = () => {
    setInvoiceData({
      ...invoiceData,
      variableCharges: [...invoiceData.variableCharges, { id: uuidv4(), type: '', amount: '' }],
    });
  };

  // Remove a charge from the list
  const removeCharge = (id) => {
    const updatedCharges = invoiceData.variableCharges.filter((charge) => charge.id !== id);
    setInvoiceData({ ...invoiceData, variableCharges: updatedCharges });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // API call to update the invoice data
    try {
      const response = await axios.put(`https://api.example.com/invoice/${invoiceId}`, invoiceData);
      console.log('Invoice successfully updated', response.data);
      // Show success message or navigate to another page
    } catch (error) {
      console.error('Error updating invoice', error);
      // Show error message
    }
  };

  return (
    <div style={{ background: '#f7f7f7', padding: '20px', minHeight: '100vh' }}>
      <Card
        style={{
          maxWidth: '800px',
          margin: '0 auto',
          padding: '20px',
          background: '#fff',
          borderRadius: '10px',
        }}
      >
        <CardTitle
          tag="h4"
          style={{
            background: '#fb9678',
            color: '#fff',
            padding: '10px',
            borderRadius: '10px',
            textAlign: 'center',
            marginBottom: '20px',
          }}
        >
          Edit Patient Invoice
        </CardTitle>
        <CardBody>
          <Form onSubmit={handleSubmit}>
            {/* Treatment Type */}
            <FormGroup>
              <Label for="treatmentType">Treatment Type</Label>
              <Input
                type="select"
                name="treatmentType"
                id="treatmentType"
                value={invoiceData.treatmentType}
                onChange={handleInputChange}
              >
                <option value="OPD">OPD</option>
                <option value="Surgery">Surgery</option>
                <option value="HealthCard">Health Card Making</option>
                <option value="IPD">IPD</option>
              </Input>
            </FormGroup>

            {/* Fixed Charges */}
            {invoiceData.treatmentType === 'OPD' || invoiceData.treatmentType === 'HealthCard' ? (
              <>
                <FormGroup>
                  <Label for="fixedCharges">Fixed Charges</Label>
                  <Input
                    type="number"
                    name="fixedCharges"
                    id="fixedCharges"
                    value={invoiceData.fixedCharges}
                    onChange={handleInputChange}
                  />
                </FormGroup>

                {/* Discounts */}
                <FormGroup>
                  <Label for="discount">Discount</Label>
                  <Input
                    type="text"
                    name="discount"
                    id="discount"
                    value={invoiceData.discount}
                    onChange={handleInputChange}
                  />
                </FormGroup>

                {/* Hospital Representative for Health Card */}
                {invoiceData.treatmentType === 'HealthCard' && (
                  <FormGroup>
                    <Label for="hospitalRep">Hospital Representative</Label>
                    <Input
                      type="text"
                      name="hospitalRep"
                      id="hospitalRep"
                      value={invoiceData.hospitalRep}
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                )}
              </>
            ) : null}

            {/* Surgery Type */}
            {invoiceData.treatmentType === 'Surgery' && (
              <>
                <FormGroup>
                  <Label for="surgeryType">Surgery Type</Label>
                  <Input
                    type="text"
                    name="surgeryType"
                    id="surgeryType"
                    value={invoiceData.surgeryType}
                    onChange={handleInputChange}
                  />
                </FormGroup>

                {/* Package Available */}
                <FormGroup>
                  <Label for="packageAvailable">Package Available</Label>
                  <Input
                    type="text"
                    name="packageAvailable"
                    id="packageAvailable"
                    value={invoiceData.packageAvailable}
                    onChange={handleInputChange}
                  />
                </FormGroup>

                {/* Discounts */}
                <FormGroup>
                  <Label for="discount">Discount</Label>
                  <Input
                    type="text"
                    name="discount"
                    id="discount"
                    value={invoiceData.discount}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </>
            )}

            {/* Variable Charges for IPD */}
            {invoiceData.treatmentType === 'IPD' && (
              <>
                <h5 style={{ fontWeight: 600, textDecoration: 'underline', paddingTop: '20px' }}>
                  Variable Charges :
                </h5>
                {invoiceData.variableCharges.map((charge) => (
                  <div
                    key={charge.id}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: '10px',
                      marginTop: '20px',
                    }}
                  >
                    <FormGroup style={{ flex: 1, marginRight: '10px' }}>
                      <Label for={`chargeType-${charge.id}`}>Charge Type</Label>
                      <Input
                        type="text"
                        name={`chargeType-${charge.id}`}
                        id={`chargeType-${charge.id}`}
                        value={charge.type}
                        onChange={(e) =>
                          handleVariableChargeChange(charge.id, 'type', e.target.value)
                        }
                      />
                    </FormGroup>
                    <FormGroup style={{ flex: 1, marginRight: '10px' }}>
                      <Label for={`chargeAmount-${charge.id}`}>Charge Amount</Label>
                      <Input
                        type="number"
                        name={`chargeAmount-${charge.id}`}
                        id={`chargeAmount-${charge.id}`}
                        value={charge.amount}
                        onChange={(e) =>
                          handleVariableChargeChange(charge.id, 'amount', e.target.value)
                        }
                      />
                    </FormGroup>
                    {invoiceData.variableCharges.length > 1 && (
                      <Button color="danger" onClick={() => removeCharge(charge.id)}>
                        Remove
                      </Button>
                    )}
                  </div>
                ))}
                <Button color="success" onClick={addNewCharge} style={{ marginBottom: '20px' }}>
                  Add New Charge
                </Button>
              </>
            )}

            {/* Payment Mode and Status */}
            <FormGroup>
              <Label for="paymentMode">Payment Mode</Label>
              <Input
                type="select"
                name="paymentMode"
                id="paymentMode"
                value={invoiceData.paymentMode}
                onChange={handleInputChange}
              >
                <option value="Cash">Cash</option>
                <option value="UPI">UPI</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="paymentStatus">Payment Status</Label>
              <Input
                type="select"
                name="paymentStatus"
                id="paymentStatus"
                value={invoiceData.paymentStatus}
                onChange={handleInputChange}
              >
                <option value="Paid">Paid</option>
                <option value="Dues">Dues</option>
              </Input>
            </FormGroup>

            {/* Show fields if payment status is "Dues" */}
            {invoiceData.paymentStatus === 'Dues' && (
              <>
                <FormGroup>
                  <Label for="promisedPaymentDate">Promised Payment Date</Label>
                  <Input
                    type="date"
                    name="promisedPaymentDate"
                    id="promisedPaymentDate"
                    value={invoiceData.promisedPaymentDate}
                    onChange={handleInputChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="guarantorDetails">Guarantor Details</Label>
                  <Input
                    type="text"
                    name="guarantorDetails"
                    id="guarantorDetails"
                    value={invoiceData.guarantorDetails}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </>
            )}

            <Button type="submit" color="primary">
              Submit Invoice
            </Button>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
};

export default EditInvoice;
