import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL, FETCH_STAFF } from '../Utils/API';

// Async thunk for fetching staff data
export const fetchStaff = createAsyncThunk('staff/fetchStaff', async (_, thunkAPI) => {
  try {
    const response = await axios.get(BASE_URL + FETCH_STAFF);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

const staffSlice = createSlice({
  name: 'staff',
  initialState: {
    staffList: [],
    loading: false,
    error: null,
  },
  reducers: {
    // Any other non-async reducers can go here
  },
  extraReducers: (builder) => {
    builder
      // Handle the pending state of the fetchStaff thunk
      .addCase(fetchStaff.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      // Handle the fulfilled state of the fetchStaff thunk
      .addCase(fetchStaff.fulfilled, (state, action) => {
        state.loading = false;
        state.staffList = action.payload;
      })
      // Handle the rejected state of the fetchStaff thunk
      .addCase(fetchStaff.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to fetch staff data';
      });
  },
});

export default staffSlice.reducer;
